













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TabSwitcher extends Vue {
  @Prop() tabs!: Array<string>;
  @Prop() activeTab = 0;

  @Prop() onTabSelected!: Function;
}
