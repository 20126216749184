






























import { Component, Prop, Vue } from "vue-property-decorator";
import AssetsList from "./device-events/AssetsList.vue";
import IncidentList from "./device-events/Incident-List.vue";

@Component({
  components: { AssetsList, IncidentList },
})
export default class DeviceEvents extends Vue {
  @Prop() selectedTab = 0;
}
