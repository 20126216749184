



























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "../ui/Loader.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";

import moment from "moment";

@Component({
  components: { Loader, ChevronLeftIcon, ChevronRightIcon },
})
export default class TagInOut extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList: any = [];

  @Prop() dateRange!: any;

  @Watch("dateRange")
  onwatchdateRange() {
    this.fetchAssetsList(
      moment(this.dateRange.startDate).format("YYYY-MM-DD"),
      moment(this.dateRange.endDate).format("YYYY-MM-DD")
    );
  }

  mounted() {
    this.fetchAssetsList("", "");
  }

  isLoading = false;
  async fetchAssetsList(startDate, endDate) {
    try {
      this.isLoading = true;

      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/tag-in-out`;

      if (startDate && endDate) {
        if (startDate == endDate) {
          url += `?fixedDate=${startDate}`;
        } else {
          url += `?startDate=${startDate}&endDate=${endDate}`;
        }
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.assetsList = await response.json();
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 15 ? 15 : length;
    this.assetsTotalPages =
      this.assetsList.length % 15 === 0
        ? Math.floor(this.assetsList.length / 15)
        : Math.floor(this.assetsList.length / 15 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 15
        ? this.assetsStartIndex + 15
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 15 <= 0 ? 0 : this.assetsStartIndex - 15;
  }
}
