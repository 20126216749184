var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12"},[(!_vm.isLoading)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible",staticStyle:{"z-index":"10"}},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList.slice(
            _vm.assetsStartIndex,
            _vm.assetsEndIndex
          )),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.tag_id))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.asset.brand || " -- ")+" "+_vm._s(asset.asset.model || " -- ")+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.operator_name || " -- ")+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_c('span',{staticClass:"px-2 py-1 bg-theme-9 text-white text-xs rounded-md"},[_vm._v(" "+_vm._s(asset.tagIn ? _vm.moment(asset.tagIn.time).format("DD-MM-YYYY hh:mm a") : "- -")+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_c('span',{staticClass:"px-2 py-1 bg-theme-6 text-white text-xs rounded-md"},[_vm._v(" "+_vm._s(asset.tagOut ? _vm.moment(asset.tagOut.time).format("DD-MM-YYYY hh:mm a") : "- -")+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/telematics/tag-in-out-detail/" + (asset.asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.hours.toFixed(2))+" hrs ")])])}),0)])]):_vm._e(),(!_vm.isLoading && _vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onPrevPagination}},[_c('ChevronLeftIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed': _vm.assetsCurrentPage == 1,
            }})],1)]),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsStartIndex + 1)+"-"+_vm._s(_vm.assetsEndIndex))])]),_vm._m(1),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsLength))])]),_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onNextPagination}},[_c('ChevronRightIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed':
                _vm.assetsCurrentPage == _vm.assetsTotalPages,
            }})],1)])])]):_vm._e(),(_vm.isLoading)?_c('Loader'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("DEVICE ID")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("DEVICE NAME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("OPERATOR NAME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TAG IN TIME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TAG OUT TIME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TOTAL USAGE HOURS")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pagination__link"},[_vm._v("of")])])}]

export { render, staticRenderFns }