



















































































import { Component, Vue } from "vue-property-decorator";
import Loader from "../../ui/Loader.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";

@Component({
  components: { Loader, ChevronLeftIcon, ChevronRightIcon },
})
export default class IncidentList extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  assetsList: any = [];
  mounted() {
    this.fetchAssetsList();
  }

  skip = 0;
  take = 50;
  count = 0;

  isLoading = false;
  async fetchAssetsList() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/history?skip=${this.skip}&take=${this.take}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.assets) {
          this.assetsList = data.assets;
        }
        if (data.count) {
          this.count = data.count;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  onNextPagination() {
    if (this.skip >= this.count) return;

    this.skip += 50;
    this.fetchAssetsList();
  }

  onPrevPagination() {
    if (this.skip <= 0) return;

    this.skip -= 50;
    this.fetchAssetsList();
  }

  getEventBgColor(event: string) {
    switch (event) {
      case "Unexpected Vibration Occured":
        return "bg-theme-11";
      case "Max Tag out time crossed":
        return "bg-theme-9";
      case "Incorrect Pin":
        return "bg-theme-1";
      default:
        return "bg-theme-6";
    }
  }
}
