













































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MapSearch extends Vue {
  @Prop() onSearchSubmit!: Function;
  @Prop() onSearchSuggestionSubmit!: Function;
  showSearchSuggestion: any = false;
  searchSuggestions: any = [];
  searchText = "";

  onSearch() {
    this.onSearchSubmit(this.searchText);
  }

  mounted() {
    const el = document.body;
    el.onclick = () => {
      this.showSearchSuggestion = false;
    };
  }

  onSearchSuggestionClicked(search) {
    this.showSearchSuggestion = false;
    this.searchText = search.address;
    this.onSearchSuggestionSubmit(search);
  }

  @Watch("searchText")
  async searchLocation(query) {
    try {
      this.showSearchSuggestion = false;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/utils/map?search=${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.length > 0) {
          this.searchSuggestions = [...data];
        }
        this.showSearchSuggestion = true;
        if (this.searchText === "") {
          this.showSearchSuggestion = false;
        }
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong!",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
