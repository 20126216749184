var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/telematics/device-event-detail/" + (asset.id)),
            })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.brand || "-")+" "+_vm._s(asset.model || "-")+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/telematics/device-event-detail/" + (asset.id)),
            })}}},[_vm._v(" "+_vm._s(asset.tag_id)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/telematics/device-event-detail/" + (asset.id)),
            })}}},[_vm._v(" "+_vm._s(asset.asset_accel_gyro_history ? Number(asset.asset_accel_gyro_history.temp).toFixed(4) || " -- " : "- -")+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/telematics/device-event-detail/" + (asset.id)),
            })}}},[_c('span',{class:{
              'text-theme-9': asset.status === 'active',
              'text-theme-6': asset.status,
            }},[_vm._v(" "+_vm._s(asset.status ? asset.status || "--" : "- -")+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/telematics/device-event-detail/" + (asset.id)),
            })}}},[_vm._v(" "+_vm._s(asset.asset_accel_gyro_history ? _vm.moment(asset.asset_accel_gyro_history.time).format("LT") : "- -")+" ")])])}),0)])]):_c('Loader')}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("ASSET")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TAG ID")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TEMPARATURE")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("STATUS")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("LAST UPDATED")])])])}]

export { render, staticRenderFns }