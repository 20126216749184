

















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "../ui/Loader.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";

@Component({
  components: { Loader, ChevronLeftIcon, ChevronRightIcon },
})
export default class UsageActual extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList: any = [];

  mounted() {
    this.fetchAssetsList();
  }

  isLoading = false;

  async fetchAssetsList() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics//list-usage-actual-tab`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.assetsList = await response.json();
        for (let i = 0; i < this.assetsList.length; i++) {
          const assetStatus = await this.fetchAssetStatus(
            this.assetsList[i].id
          );
          this.assetsList[i] = { ...this.assetsList[i], status: assetStatus };
        }
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAssetStatus(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/device-status/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const status = await response.json();
        return status.status;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 5 ? 5 : length;
    this.assetsTotalPages =
      this.assetsList.length % 5 === 0
        ? Math.floor(this.assetsList.length / 5)
        : Math.floor(this.assetsList.length / 5 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 5
        ? this.assetsStartIndex + 5
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 5 <= 0 ? 0 : this.assetsStartIndex - 5;
  }
}
