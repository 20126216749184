





























































import { Component, Vue } from "vue-property-decorator";
import TabSwitcher from "../components/ui/Tab-Switcher.vue";
import GeoTagging from "../components/telematics/Geo-Tagging.vue";
import GeoTracker from "../components/telematics/Geo-Tracker.vue";
import DeviceEvents from "../components/telematics/Device-Events.vue";
import TagInOut from "../components/telematics/Tag-InOut.vue";
import DaterangePicker from "../components/ui/Daterange-Picker.vue";
import AssetNewSite from "../components/new-asset/Asset-NewSite.vue";
import AssetNewLocation from "../components/new-asset/Asset-NewLocation.vue";
import UsageActual from "../components/telematics/Usage-Actual.vue"

@Component({
  components: {
    TabSwitcher,
    GeoTagging,
    GeoTracker,
    DeviceEvents,
    TagInOut,
    DaterangePicker,
    AssetNewSite,
    AssetNewLocation,
    UsageActual
  },
})
export default class Telematics extends Vue {
  tabs = [
    "Device events",
    "Tag In/Out",
    "Usage Vs Actual",
    "GPS tracker",
    "Geo-tagging",
  ];
  selectedTeleTab = 0;
  onTeleTabSelected(index: number) {
    this.selectedTeleTab = index;

    if (index === 3) {
      this.fetchAllAssetSites();
    }
  }

  selectedDeviceEventTab = 0;
  mounted() {
    const isIncidentLog: any =
      this.$router.currentRoute.query.incidentLog || null;
    if (isIncidentLog) {
      this.selectedDeviceEventTab = 1;
    }

    const tagInOut: any = this.$router.currentRoute.query.tagInOut || null;
    if (tagInOut) {
      this.selectedTeleTab = 1;
    }
  }

  dateRange = "";
  onDateRangeChange(dateRange) {
    this.dateRange = dateRange;
  }

  // Modal
  // New-Site
  allSites: any = [];

  showNewSiteModal = false;
  onCloseNewSiteModal() {
    this.showNewSiteModal = false;
  }
  onOpenNewSiteModal() {
    this.showNewSiteModal = true;
  }

  onAssetSiteAddedSuccess() {
    this.fetchAllAssetSites();
  }

  // New location
  showNewLocationModal = false;
  onCloseNewLocationModal() {
    this.showNewLocationModal = false;
  }
  onOpenNewLocationModal() {
    this.showNewLocationModal = true;
  }

  onAssetLocationAddedSuccess() {
    // this.fetchAllAssetLocations();
  }

  async fetchAllAssetSites() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.allSites = await response.json();
      }
    } catch (e) {
      console.error(e);
    }
  }
}
