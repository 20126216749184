


































































































import { Component, Vue } from "vue-property-decorator";
import Loader from "../../ui/Loader.vue";

@Component({
  components: { Loader },
})
export default class AssetList extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  assetsList: any = [];
  mounted() {
    this.fetchAssetsList();
  }

  isLoading = false;
  async fetchAssetsList() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-devices`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.assetsList = await response.json();
        for (let i = 0; i < this.assetsList.length; i++) {
          const assetStatus = await this.fetchAssetStatus(
            this.assetsList[i].id
          );
          this.assetsList[i] = { ...this.assetsList[i], status: assetStatus };
        }

        this.assetsList = this.assetsList.sort((a,b) => {
          const x = a.status == 'active' ? 1 : 0;
          const y = b.status == 'active' ? 1 : 0;
          return y-x;
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAssetStatus(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/device-status/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const status = await response.json();
        return status.status;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
