
















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { PlusIcon } from "vue-feather-icons";
import "leaflet/dist/leaflet.css";
// import "leaflet-search/src/leaflet-search.css";
import L from "leaflet";
// import LeafletSearch from "leaflet-search";
import Loader from "../../components/ui/Loader.vue";
import MapSearch from "../../components/ui/MapSearch.vue";

@Component({
  components: {
    PlusIcon,
    Loader,
    MapSearch,
  },
})
export default class GeoTagging extends Vue {
  latitude = "";
  latitudeError = "";

  longitude = "";
  longitudeError = "";

  @Watch("latitude")
  watchlatitude() {
    this.latitudeError = "";
  }

  @Watch("longitude")
  watchlongitude() {
    this.longitudeError = "";
  }

  access_token =
    "pk.eyJ1Ijoia3VzaGFua3VyeGQiLCJhIjoiY2s1MXZvbHAxMGZoaDNmcGwzdXo4ZHgwNSJ9.kqBU5Wrn2yMM1RXbK3T9Gg";
  center: any = [22.9868, 87.855];

  mounted() {
    this.fetchAllGeoTaggedSites();
  }

  mapDiv: any = null;
  initLeafletMap() {
    this.mapDiv = L.map("mapContainer").setView(this.center, 4);
    const myIcon = L.icon({
      iconUrl: require("../../assets/map-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-3, -76],
    });

    this.watchThemeChange();

    // const searchLayer = L.layerGroup().addTo(this.mapDiv);

    // this.mapDiv.addControl(
    //   new LeafletSearch({
    //     layer: searchLayer,
    //     filterData: (textSearch) => {
    //       this.searchLocation(textSearch);
    //     },
    //     position: "topright",
    //   })
    // );

    let selectedMarker: any = null;
    this.mapDiv.on("click", (e: any) => {
      if (selectedMarker !== null) {
        this.mapDiv.removeLayer(selectedMarker);
      }
      selectedMarker = L.marker(e.latlng, { icon: myIcon });
      this.mapDiv.addLayer(selectedMarker);
      // Set selected lat lng to form
      this.latitude = e.latlng.lat;
      this.longitude = e.latlng.lng;
    });
  }

  onSearchSubmit(searchText) {
    this.searchLocation(searchText);
  }

  onSearchSuggestionSubmit(data) {
    this.addLocationMarkers(data);
  }

  async searchLocation(query) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/utils/map?search=${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.length > 0) {
          this.addLocationMarkers(data[0]);
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Error!",
            text: "Location not found, Try using pin-code",
            type: "Danger",
          });
        }
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong!",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  locationMarker: any = null;
  addLocationMarkers(asset) {
    if (asset.latitude === null || asset.longitude === null) {
      return;
    }

    if (this.mapDiv === null) {
      this.initLeafletMap();
    }
    const myIcon = L.icon({
      iconUrl: require("../../assets/location-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-4, -30],
    });

    if (this.locationMarker !== null) {
      this.mapDiv.removeLayer(this.locationMarker);
    }

    this.locationMarker = L.marker([asset.latitude, asset.longitude], {
      icon: myIcon,
    })
      .bindPopup(
        `<p class='font-bold'>${asset.city || "-"}<br/> ${
          asset.state || "-"
        } , ${asset.country || "-"}</p>`
      )
      .openPopup();

    this.mapDiv.addLayer(this.locationMarker);
    this.mapDiv.flyTo([asset.latitude, asset.longitude], 18.0);
  }

  @Watch("$store.state.themeAT")
  watchThemeChange() {
    L.tileLayer(
      `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${this.access_token}`,
      {
        attribution: "Durbin",
        maxZoom: 18,
        id:
          this.$store.state.themeAT === "dark"
            ? "mapbox/dark-v10"
            : "mapbox/light-v10",
        accessToken: this.access_token,
      }
    ).addTo(this.mapDiv);
  }

  site = "";
  allSites: any = [];

  isLoading = false;
  async fetchAllAssetSites() {
    try {
      this.isLoading = true;

      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.allSites = await response.json();
        if (this.allSites.length > 0) {
          this.site = this.allSites[0].id;
        }
        const addMarker = this.addMyMarkerToMap;
        setTimeout(() => {
          this.initLeafletMap();
          this.assetsList.map(l =>
            setTimeout(() => addMarker(l.latitude, l.longitude, l.name), 10)
          );
        }, 10);
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  addMyMarkerToMap(lat, lng, name) {
    const myIcon = L.icon({
      iconUrl: require("../../assets/map-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-4, -30],
    });

    const marker = L.marker([Number(lat), Number(lng)], {
      icon: myIcon
    })
      .bindPopup(`<p>${name}</p>`)
      .openPopup();

    this.mapDiv.addLayer(marker);
    // this.locationBoundArray.push([asset.site.latitude, asset.site.longitude]);
    // this.mapDiv.fitBounds(this.locationBoundArray, { padding: [84, 84] });
  }

  assetsList: any = [];
  async fetchAllGeoTaggedSites() {
    try {
      this.isLoading = true;

      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site/list-tagged`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.assetsList = await response.json();
        this.fetchAllAssetSites();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  async updateGeotaggingData() {
    if (this.site === "") {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "Site not found",
        type: "Danger",
      });
      return;
    }
    try {
      const body = {
        latitude: parseFloat(this.latitude).toFixed(10),
        longitude: parseFloat(this.longitude).toFixed(10),
      };
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site/geo-tagging/${this.site}`,
        {
          method: "PUT",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Geo-tagging added successfully",
          type: "Success",
        });
        this.latitude = "";
        this.longitude = "";
        this.fetchAllGeoTaggedSites();
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "latitude":
            this.latitudeError = error[0].message;
            break;
          case "longitude":
            this.longitudeError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
